@mixin defaultAuthenticatedbackgroundPageStyle {
  position: relative;
  overflow-y: auto;
  width: 100%;
  height: calc(100vh - 80px);

  background-color: var(--bg-background);
}

.onBoardingContentStyle {
  height: 80%;
}

.onBoardingPageMainStyle {
  @include defaultAuthenticatedbackgroundPageStyle;

  .onBoardingLandingPageStyle {
    text-align: center;

    .onBoardingHashTagStyle {
      margin-top: 174px;
    }
    .onBoardingTitleStyle {
      margin-top: 60px;
    }
    .onBoardingSubTitleStyle {
      margin-top: 16px;
    }
    .onBoardingButtonStyle {
      margin-top: 56px;
    }
  }
}

.onBoardingPageMainStylePolka {
  @include defaultAuthenticatedbackgroundPageStyle;
  background-image: radial-gradient(#313131 20%, transparent 10%);
  background-size: 15px 15px;
}

.onBoardingConnectorCardsStyle {
  padding: 16px 0px 0px 0px;
}

.workspaceCardsStyle {
  padding: 24px 0px 0px 0px;
}

.onBoardingWokspaceTitle,
.onBoardingPipeLineTitle {
  padding: 32px 0px 0px 0px;
}

.onBoardingPipeLineSubTitle {
  padding: 32px 0px 0px 0px;
  align-items: center;
  display: flex;

  .onBoardingPipeLineSearchBoxStyle {
    display: flex;
    justify-content: flex-end;
  }
}

.onBoardingManufactureCardStyle {
  margin: 10px 0px 0px 0px;
  .onBoardingCardsFlex {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    .onBoardingCardMultiplierStyle {
      margin: 24px 0px 0px 15px;
      flex: 0 0 auto;
    }
  }
}
.onBoardingPipeLineDescription {
  padding: 8px 0px 0px 0px;
}

.onBoardingDeviceList {
  justify-content: center;
  align-items: center;
  display: flex;
}

.onBoardingDeviceListTitle {
  padding: 8px 16px 0px 16px;
}

.onBoardingDeviceListDescription {
  padding: 8px 0px 0px 16px;
}
.deviceListConfigSkeleton {
  height: 425px;
  padding: 24px 0px 0px 0px;
  margin: 0px 0px 14px 0px;
  background: #313131;
  overflow-y: auto;
  .onBoardingManufactureCardStyle {
    margin: 10px 0px 10px 0px;
    .onBoardingCardsFlex {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      .onBoardingCardMultiplierStyle {
        margin: 24px 0px 0px 15px;
        flex: 0 0 auto;
      }
    }
  }
}

.deviceListPageTitleRow {
  padding: 32px 0px 0px 0px;

  .deviceListPageSubTitle {
    margin: 12px 0px 0px 0px;
  }
}

.deviceListsMainRow {
  padding: 24px 0px 0px 0px;
}
.deviceListBottomStyling {
  padding: 0px 0px 24px 0px;
}

.onBoardingFooterMainRow {
  bottom: 10px;
  right: 50px;

  .onBoardingFooterSkeleton {
    height: 80px;
    display: flex;

    .onBoardingFooterStyle {
      display: flex;
      align-items: center;
      justify-content: center;
      .onBoardingFooterProgressBar {
        margin: 10px 0px 0px 0px;
      }
      .onBoardingFooterButtons {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
    }
  }
}

.componentCardSkeletonStyle {
  margin: 20px 0px 0px 0px;
  .componentCardSkeletonMargin {
    align-items: center;
  }
}

.configureCardContainer {
  padding: 16px 16px 16px 16px;
}

.configureLineRow {
  padding: 16px 0px 16px 0px;
}

.configureFormRow {
  padding-left: 88px;
}

.configureInputDescription {
  padding-top: 8px;
}

.setupCardPrerequisites {
  padding-top: 16px;
}

.setuCardInstructions {
  padding-top: 8px;
}

.componentCardTextStyle {
  padding: 10px 0px 0px 0px;
  text-align: start;
}

.connectorCardDashedSkeleton {
  justify-content: center;
  display: flex;
  align-items: center;
  margin: 20px 0px 0px 0px;
}
.connectorCardDashedHeight {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0px 0px 0px;
}
